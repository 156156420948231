<template>
  <section v-if="account" class="section">

    <div v-if="showSave" class="buttons">
      <button class="button is-dark" @click="saveProfile()">
        Save
      </button>
    </div>

    <div class="list has-visible-pointer-controls has-overflow-ellipsis">
      <div class="list-item">
        <div class="list-item-content">
          <div class="list-item-title">First Name</div>
        </div>
        <div class="list-item-controls">
          <FormField v-model="account.firstName" />
        </div>
      </div>
      <div class="list-item">
        <div class="list-item-content">
          <div class="list-item-title">Last Name</div>
        </div>
        <div class="list-item-controls">
          <FormField v-model="account.lastName" />
        </div>
      </div>
      <div class="list-item">
        <div class="list-item-content">
          <div class="list-item-title">Email</div>
        </div>
        <div class="list-item-controls">
          <FormField v-model="account.email" />
        </div>
      </div>
      <div class="list-item">
        <div class="list-item-content">
          <div class="list-item-title">Company</div>
        </div>
        <div class="list-item-controls">
          <FormField v-model="account.company" />
        </div>
      </div>

      <div class="list-item is-clickable" @click="showUpdatePasswordModal = true">
        <div class="list-item-content">
          <div class="list-item-title">Change password</div>
        </div>
        <div class="list-item-controls">
          <Icon icon="chevron-right" />
        </div>
      </div>
    </div>

    <button class="button is-black is-fullwidth is-hidden-tablet mt-auto" @click="logOut()">
      Logout
    </button>

    <UpdatePasswordModal
      v-if="showUpdatePasswordModal"
      @close="showUpdatePasswordModal = false"
    />

  </section>
</template>

<script>
import { ref, watch } from 'vue'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import useLogout from '@/composables/useLogout'
import useSignup from '@/composables/useSignup'
import FormField from '@/components/Forms/FormField.vue'
import Icon from '@/components/Icon.vue'
import UpdatePasswordModal from '@/components/Modals/UpdatePasswordModal.vue'

export default {
  components: { FormField, Icon, UpdatePasswordModal },
  setup() {
    const { user } = getUser()
    const { document: account } = getDocument('accounts', user.value.uid)
    const { updateProfile } = useSignup()

    const showSave = ref(false)

    watch(account, (_, oldVal) => {
      console.log({oldVal})
      if (oldVal) showSave.value = true
    }, { deep: true })

    const saveProfile = async () => {
      await updateProfile(user.value.uid, {
        ...account.value
      })
      showSave.value = false
    }

    const showUpdatePasswordModal = ref(false)

    const logOut = async () => {
      const { logout } = useLogout()
      await logout()
      window.location.href = window.location.origin
    }

    return {
      account,
      showSave,
      saveProfile,

      showUpdatePasswordModal,

      logOut,
    }
  }
}
</script>

<style scoped>
.section {
  min-height: calc(100vh - env(safe-area-inset-bottom) - 58px - 101px);
  display: flex;
  flex-direction: column;
  padding-bottom: calc(2.5rem + env(safe-area-inset-bottom));
}

.buttons {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  z-index: 555;
}
</style>