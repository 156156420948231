<template>
  <Modal
    v-if="!showSuccessModal"
    title="Update Password"
    :isSmall="true"
    @close="$emit('close')"
  >
    <FormField
      label="Current Password"
      type="password"
      v-model="oldPassword"
    />

    <FormField
      label="New Password"
      type="password"
      v-model="password"
    />

    <FormField
      label="Confirm New Password"
      type="password"
      v-model="passwordConfirm"
    />

    <article v-if="error" class="message is-danger">
      <div class="message-body">
        {{ error }}
      </div>
    </article>

    <template v-slot:footer>
      <button
        :class="`button is-black ${isPending && 'is-loading'}`"
        :disabled="!oldPassword && !password" 
        @click="handleSubmit()"
      >
        Update
      </button>
      <button class="button" @click="$emit('close')">
        Cancel
      </button>
    </template>
  </Modal>

  <Modal
    v-if="showSuccessModal"
    title="Password Changed!"
    :isSmall="true"
    @close="$emit('close')"
  >
    <p>You have successfully changed your password</p>
    <template v-slot:footer>
      <button class="button is-black" @click="$emit('close')">Okay, got it!</button>
    </template>
  </Modal>
</template>

<script>
import { ref, inject } from 'vue'
import getUser from '@/composables/getUser'
import useLogin from '@/composables/useLogin'
import Modal from '@/components/Modals/Modal.vue'
import FormField from '@/components/Forms/FormField.vue'

export default {
  emits: ['close'],
  components: { Modal, FormField },
  setup() {
    const { user } = getUser()
    const { error, login, doUpdatePassword } = useLogin()

    const oldPassword = ref('')
    const password = ref('')
    const passwordConfirm = ref('')

    const showSuccessModal = ref(false)
    const isPending = inject('isPending')

    const handleSubmit = async () => {
      error.value = null
      if (password.value != passwordConfirm.value) {
        error.value = 'Passwords do not match'
        return
      }

      isPending.value = true
      const loggedIn = await login(user.value.email, oldPassword.value)
      if (!loggedIn) return
      const res = await doUpdatePassword(password.value)

      isPending.value = false
      console.log('updatedPassword res', res)
      if (!res && !error.value) showSuccessModal.value = true
  
    }

    return {
      oldPassword,
      password,
      passwordConfirm,

      handleSubmit,
      showSuccessModal,

      isPending,
      error
    }
  }
}
</script>